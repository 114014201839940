import ActiveLevel0Icon from '@/assets/images/freeze-color/active-level-0.svg'
import ActiveLevel1Icon from '@/assets/images/freeze-color/active-level-1.svg'
import ActiveLevel2Icon from '@/assets/images/freeze-color/active-level-2.svg'
import ActiveLevel3Icon from '@/assets/images/freeze-color/active-level-3.svg'
//辨認當前domain是否為需要對其隱藏部分功能的domain，目前指定需對sofinq站點隱藏，為了開發方便所以也對localhost隱藏
const sofinqDomains=['admin.sofinq.otso-dev.com','admin.sofinq-test.otso-dev.com','admin.sofinq.com']
export const isSofinqDomain = () => {
  const domain = window.location.hostname
  return sofinqDomains.includes(domain)
}

export function getActiveLevel (activeLevel: 0 | 1 | 2 | 3): Record<'icon' | 'tip', string> {
  switch(activeLevel) {
  case 0: return { icon: ActiveLevel0Icon, tip: 'activeLevel0Tip' }
  case 1: return { icon: ActiveLevel1Icon, tip: 'activeLevel1Tip' }
  case 2: return { icon: ActiveLevel2Icon, tip: 'activeLevel2Tip' }
  case 3: return { icon: ActiveLevel3Icon, tip: 'activeLevel3Tip' }
  default: return { icon: '', tip: ''}
  }
}

// SoFinQ IV 站點 domain, 取代邏輯與SoFinX不同
export const SOFINQ_DOMAIN =  `https://${window.location.hostname.replace(/^admin\./, '')}/`

const universeDomain = ['sofinx-admin.universe.otso-dev.com', 'universe-admin.sofinx.com']
export const isUniverseDomain = () => {
  const domain = window.location.hostname
  return universeDomain.includes(domain)
}